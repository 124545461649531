






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TimeGrid',
})
export default class extends Vue {
  @Prop({ type: Number, default: 144 })
  unit!: number
}
